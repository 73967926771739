<template>
  <ul>
    <router-link tag="li" to="/lk/tutor-selection">
      <a href="#">
        <icon-attestation fill="#fff"  /> Отбор в тренеры
      </a>
    </router-link>
    <router-link tag="li" to="/lk/online">
      <a href="#">
        <img src="/pic/lk-programm.svg">Онлайн программа
      </a>
    </router-link>
    <router-link tag="li" to="/lk/seminars">
      <a href="#"><img src="/pic/lk-seminar.svg">Семинары</a>
    </router-link>
    <router-link tag="li" to="/lk/tutor-tasks">
      <a href="#">
        <icon-attestation fill="#fff"  /> Индивидуальные задания
      </a>
    </router-link>
    <router-link tag="li" to="/lk/requests">
      <a href="#"><img src="/pic/lk-order.svg">Мои заявки</a>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/requests/online" :class="{'active': $route.name === 'OnlineRequests'}">Онлайн программа</router-link></div>
        <div><router-link to="/lk/requests/offline">Семинары</router-link></div>
      </div>
    </router-link>
    <router-link tag="li" to="/lk/docs">
      <a href="#"><img src="/pic/lk-doc.svg">Мои документы</a>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/docs/online" :class="{'active': $route.name === 'OnlineDocs'}">Онлайн программа</router-link></div>
        <div><router-link to="/lk/docs/offline">Семинары</router-link></div>
      </div>
    </router-link>
    <li v-if="$hasRole('ROLE_TUTOR')"><a href="https://exportedu.ru/profile/tutor-libs"><img src="/pic/lk-lib.svg">Библиотека тренера</a></li>
    <router-link tag="li" to="/lk/ebs">
      <a href="#"><img src="/pic/lk-elib.svg">Электронная библиотека</a>
    </router-link>
    <router-link tag="li" to="/lk/libs" v-if="user.is_corporate">
      <a href="#"><img src="/pic/lk-elib.svg">Библиотека</a>
    </router-link>
    <li :class="{'active': settingsRoutes.indexOf($route.name) > -1}">
      <router-link to="/lk/settings"><img src="/pic/lk-settings.svg">Настройки</router-link>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/settings" :class="{'active': $route.name === 'ProfileSettings'}">Личные данные</router-link></div>
        <div><router-link to="/lk/company">Данные о компании</router-link></div>
        <div><router-link to="/lk/subscribes">Рассылки и подписки</router-link></div>
      </div>
    </li>
  </ul>
</template>

<script>
import roleRoutersMixin from "@/mixins/roleRoutersMixin";
import userMixin from "@/mixins/userMixin";
import IconAttestation from "@/components/icons/IconAttestation.vue";

export default {
  name: "ProfileMenuUser",
  components: {IconAttestation},
  mixins: [roleRoutersMixin, userMixin],
}
</script>

<style scoped>

</style>